<template>
    <b-container fluid="">
        <errors-bag-list/>

        <b-card no-body>
            <b-card-header header-tag="header" class="text-center">
                <b-row>
                    <b-col cols="4">
                        <b-form-group description="Поиск" class="mb-0">
                            <b-form-input
                                debounce="750"
                                v-model="filters.search"
                                placeholder="Поиск по имени/логину/email"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="3">
                        <b-form-group description="Коэффициент аутстаффа" class="mb-0">
                            <b-form-select
                                v-model="filters.hasOutstaffCoefficient"
                                :options="HAS_OUTSTAFF_COEFFICIENT_OPTIONS"
                                placeholder="Коэффициент аутстаффа"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="3">
                        <b-form-group description="Коэффициент" class="mb-0">
                            <b-form-select
                                v-model="filters.outstaffCoefficientId"
                                :options="outstaffCoefficientOptions"
                                placeholder='Коэффициент'
                                :disabled="filters.hasOutstaffCoefficient === 'no'"
                            >
                                <template #first>
                                    <b-form-select-option :value="null">Все</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col cols="2">
                        <div class="pull-right">
                            <b-button variant='success' :to="{ name: 'UsersCreate' }">Новый пользователь</b-button>
                        </div>
                    </b-col>
                </b-row>
            </b-card-header>
        </b-card>

        <b-table
            stipred
            :responsive="true"
            :items="items"
            :fields="fields"
            head-variant="dark"
            @row-dblclicked="goTo"
            :no-local-sorting="noLocalSort"
        >
            <template #cell(email)="data">{{ data.item.email || '---' }}</template>

            <template #cell(actions)="row">
                <div class="text-right">
                    <b-button-group>
                        <b-button
                            target="_blank"
                            variant="outline-dark"
                            v-if="row.item.login_barcode"
                            :to="routerBarcode( row.item )"
                        >
                            <b-icon-upc/>
                        </b-button>

                        <b-button variant="outline-primary" :to="routerUser( row.item )">
                            <b-icon-pencil-fill/>
                        </b-button>
                    </b-button-group>
                </div>
            </template>
        </b-table>

        <b-row>
            <b-col cols="6">
                <span>Показано с {{ meta.current_page * meta.per_page - meta.per_page + 1 }} по {{ meta.current_page * meta.per_page - meta.per_page + items.length }} из {{ meta.total_rows }} записей</span>
            </b-col>
            <b-col cols="6" class="d-flex justify-content-end">
                <b-pagination
                    v-model="meta.current_page"
                    :total-rows="meta.total_rows"
                    :per-page="meta.per_page"
                    @input="fetchUsers"
                />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import ErrorsBagList from '@components/_common/ErrorsBagList';

    import ErrorsBag from '@utils/errorsBag';
    import { USERS_ENDPOINT } from '@utils/endpoints';

    const HAS_OUTSTAFF_COEFFICIENT_OPTIONS = [
        { value: 'yes', text: 'Да' },
        { value: 'no', text: 'Нет' },
    ]

    export default {
        name: "UsersList",
        components: { ErrorsBagList },
        data() {
            return {
                ErrorsBag,

                filters: {
                    search: null,
                    hasOutstaffCoefficient: 'yes',
                    outstaffCoefficientId: null
                },

                users: [],
                fields: [
                    { key: 'id', label: '#' },
                    { key: 'email', label: 'Email' },
                    { key: 'username', label: 'Логин' },
                    { key: 'fullname', label: 'Имя' },
                    { key: 'actions', label: 'Действия' },
                ],

                meta: {
                    current_page: 1,
                    total_rows: 0,
                    per_page: 25,
                },

                HAS_OUTSTAFF_COEFFICIENT_OPTIONS,
                outstaffCoefficients: []
            };
        },
        watch: {
            filters: {
                deep: true,
                handler() {
                    this.fetchUsers();
                },
            },
        },
        computed: {
            items() {
                return this.users;
            },
            noLocalSort() {
                return true;
            },
            outstaffCoefficientOptions() {
                return this.outstaffCoefficients.map(({ id, coefficient }) => ({ value: id, text: coefficient }));
            }
        },
        methods: {
            generationLink( number ) {
                return number;
            },

            goTo( record ) {
                this.$router.push( this.routerUser( record ) );
            },
            routerUser( record ) {
                return { name: 'UsersEdit', params: { id: record.id } };
            },
            routerBarcode( record ) {
                return { name: 'UsersBarcode', params: { id: record.id } };
            },

            remove( id ) {
                ErrorsBag.discard();

                if ( confirm( 'Вы уверены, что хотите удалить этот элемент?' ) ) {
                    this.$http
                        .delete( USERS_ENDPOINT + '/' + id )
                        .then( null, response => ErrorsBag.fill( response.body, false ) )
                        .finally( () => this.fetchUsers() );
                }
            },

            fetchUsers() {
                this.users = [];

                let params = {
                    orders: {fullname: 'asc'},
                    page: this.meta.current_page,
                    filters: {
                        search: this.filters.search,
                        hasOutstaffCoefficient: this.filters.hasOutstaffCoefficient,
                        outstaffCoefficientId: this.filters.outstaffCoefficientId,
                    }
                };

                this.$http
                    .get( USERS_ENDPOINT, { params } )
                    .then( response => {
                        this.users = response.data.data.data;

                        this.meta.current_page = response.data.data.current_page;
                        this.meta.per_page = response.data.data.per_page;
                        this.meta.total_rows = response.data.data.total;
                    } );
            },

            loadOutstaffCoefficients() {
                this.$http
                    .get( USERS_ENDPOINT + '/outstaff-coefficients' )
                    .then( response => response.data )
                    .then( outstaffCoefficients => {
                        this.outstaffCoefficients = outstaffCoefficients
                    })
                    .catch( response => ErrorsBag.fill( response.body ) );
            }
        },
        created() {
            this.fetchUsers();
            this.loadOutstaffCoefficients();
        }
    }
</script>
